import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  textA: {
    textDecoration: "none",
    color: "#212121",
  },
  espaco: {
    marginRight: "10px",
  },
  iconCurcular: {
    width: '30px',
    height: '30px',
    marginLeft: '15px'
  },
  tudo: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  ico: {
    width: '30px !important',
    height: '30px !important',
  },
  green: {
    // color: '#00C853 !important',
    backgroundColor: '#00C853 !important',
  }
}));
