import { memo, useCallback, useRef, useState, forwardRef } from "react";
import Webcam from "react-webcam";
import Axios from "axios";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Send from "@material-ui/icons/Send";
import TextField from "@material-ui/core/TextField";

import LogoFasoft from "../../assets/images/fasoft.svg";
import LogoUniRV from "../../assets/images/unirv.svg";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles } from "./style";

import Resizer from "react-image-file-resizer";


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Camprovante = memo(() => {
  const classes = useStyles();

  const webcamRef = useRef(null);
  const [open, setOpen] = useState(false);

  const [email, setEmail] = useState(null);
  const [senha, setSenha] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);

  const ValidaCampos = useCallback(async (tipo, valor) => {
    if (tipo === "email") {
      setEmail(valor);
    }

    if (tipo === "senha") {
      setSenha(valor);
    }

    if (tipo === "img") {
      setImgSrc(valor);
    }
  }, [setEmail, setSenha, setImgSrc]);



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpenMsg(false);

    if (sus) {
      alert("Comprovante enviado com sucesso!");
      window.location.href = "/";
    }
  };

  const [openStatus, setOpenStatus] = useState(false);

  const [Msg, setMsg] = useState("false");

  const [openMsg, setOpenMsg] = useState(false);

  const [sus, setSus] = useState(false);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        700,
        700,
        "JPEG",
        72,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const base64 = useCallback(async (e, b) => {
    var imageFile = await resizeFile(e);
    console.log(imageFile);
    // var fileReader = new FileReader();
    // fileReader.onload = function (fileLoadedEvent) {
    //   const srcData = fileLoadedEvent.target.result;
    //   const newImage = document.createElement('img');
    //   newImage.src = srcData;
    // }
    // fileReader.readAsDataURL(imageFile);
    ValidaCampos('img', imageFile);

  }, [ValidaCampos]);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot({});
    setImgSrc(imageSrc);
    setOpen(false);
  }, []);

  const salvar = () => {

    // console.log(email);
    // console.log(senha);
    // console.log(imgSrc.result);
    setOpenStatus(true);
    Axios.get("https://calouros-2022-1.herokuapp.com/calouros")
      .then((response) => {

        const DataTodos = response.data;
        const DataTodosFiltrados = DataTodos.filter(function (item) {
          return item.email === email;
        });
        if (DataTodosFiltrados.length === 0) {
          setOpenStatus(false);
          setMsg("E-mail não Cadastrado");
          alert("E-mail não Cadastrado");
        } else {
          const id = DataTodosFiltrados[0].id;
          Axios.put(`https://calouros-2022-1.herokuapp.com/calouros/${id}`, {
            name: DataTodosFiltrados[0].name,
            email: email,
            periodo: DataTodosFiltrados[0].periodo,
            password: 'senha',
            confirmPassword: 'senha',
            turma: DataTodosFiltrados[0].turma,
            imgUrl: "false", // confirmação de pagamento
            imgBi: imgSrc,
          })
            .then((response) => {
              setMsg("Comprovante enviado com sucesso!");
              setOpenMsg(true);
              setSus(true);
            })
            .catch(function (error) {
              setMsg(error.response.data);
              setOpenMsg(true);
            })
            .finally(() => {
              setOpenStatus(false);
            });
        }

      });

  };


  return (
    <div className={classes.todo}>
      <img src={LogoFasoft} alt="logo Fasoft" width={"70%"} />

      <br />
      <form className={classes.form} onSubmit={(e) => console.log(e)}>

        <TextField
          label="E-mail"
          variant="outlined"
          name="email"
          required
          onChange={(e) => ValidaCampos('email', e.target.value)}
        />
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={(e) => { base64(e.target.files[0], e) }}
        />
        <label htmlFor="contained-button-file" >
          <Button variant="contained" color="primary" component="span" className={classes.button}>
            Upload do Comprovante
          </Button>
        </label>

        {/* <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          endIcon={<PhotoCamera />}
          onClick={handleClickOpen}
          size="large"
          fullWidth
        >
          Tirar Foto Comprovante

        </Button> */}

        <div className={classes.centerImg}>
          {imgSrc && <img src={imgSrc} alt="logo Fasoft" className={classes.imgRecibo} />}
        </div>



        {/* <TextField
          label="Senha"
          variant="outlined"
          name="senha"
          required
          type="password"
          onChange={(e) => ValidaCampos('senha', e.target.value)}
        /> */}

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<Send />}
          size="large"
          fullWidth
          disabled={email !== null && imgSrc !== null ? false : true}
          onClick={() => salvar()}
        >
          Enviar Comprovante
        </Button>
      </form>
      <br />
      <img src={LogoUniRV} alt="logo Fasoft" width={"30%"} />
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={classes.dialog}
      >
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          className={classes.webcam}
        />

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<PhotoCamera />}
          onClick={capture}
          size="large"
          fullWidth
        >
          Tirar Foto
        </Button>
      </Dialog>

      <Backdrop
        className={classes.backdrop}
        open={openStatus}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        autoHideDuration={1000}
        open={openMsg}
        onClose={handleClose}
        message={Msg}
      ></Snackbar>
    </div>
  );
});

export default Camprovante;
