import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  root: {},
  todo: {
    maxWidth: "500px",
    height: "100vh",

    margin: "auto",
    borderRadius: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
  },
  btsQR: {
    width: "calc(100% + 10px)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  icoQR: {
    width: "45px !important",
    height: "45px !important",
    margin: "5px !important",
  },
  btCopy: {
    width: "100%",
    height: "45px",
    margin: "5px !important",
  },
  quebraLinha: {
    wordBreak: "break-all",
  },
  container: {
    width: "calc(100% - 20px)",
    padding: '10px',
    margin: '10px 0px',
    boxShadow: '0px 0px 10px #CCC',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
},
}));
