import React, { memo, useCallback, useState } from "react";
import { useStyles } from "./style";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import copy from 'copy-to-clipboard';

import img1 from "../../assets/images/img1.jpeg";
import icoQR from "../../assets/images/qrcode.svg";
import QrCode from "../../assets/images/qrcode.jpg";
import QrCodeDoacao from "../../assets/images/6R.jpeg";
import Doacao from "../../assets/images/doacao.jpeg";

const Home: React.FC = memo(() => {
  const classes = useStyles();

  const gincana = () => {
    window.open("./docs/gincana.pdf");
  }

  const regulamentoJogos = () => {
    window.open("./docs/regulamento_jogos.pdf");
  }

  const edital = () => {
    window.open("./docs/edital.pdf");
  }

  const regulamento = () => {
    window.open("./docs/regulamento.pdf");
  }

  const grupo = () => {
    window.open("https://forms.gle/iBF8PNboS4Wh5sXWA");
  }

  const [open, setOpen] = useState(false);
  const [openDoacao, setOpenDoacao] = useState(false);
  const [openQrDoacao, setOpenQrDoacao] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenDoacao = () => {
    setOpenDoacao(true);
  };

  const handleClickOpenQrDoacao = () => {
    setOpenQrDoacao(true);
  };

  const [Msg, setMsg] = useState("false");
  const [openMsg, setOpenMsg] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpenMsg(false);
    setOpenDoacao(false);
    setOpenQrDoacao(false);
  };

  // value="00020101021126610014br.gov.bcb.pix0129vanessamarquesalves@gmail.com0206Sesoft520400005303986540515.005802BR5920VANESSA M A DA SILVA6009RIO VERDE62100506Sesoft63042303"
  const copyPix = () => {
    const pix =
      "00020101021126710014br.gov.bcb.pix0129vanessamarquesalves@gmail.com0216XIIGINCANAFASOFT52040000530398654045.005802BR5920VANESSA M A DA SILVA6009RIO VERDE62200516XIIGINCANAFASOFT6304D6FD"
    copy(pix);
    setMsg("Copiado para a área de transferência!");
    setOpenMsg(true);
  };

  const copyDoacao = async () => {
    const pix =
      "00020101021126770014br.gov.bcb.pix0129vanessamarquesalves@gmail.com0222GincanaFasoftSolidario52040000530398654046.005802BR5920VANESSA M A DA SILVA6009RIO VERDE62260522GincanaFasoftSolidario63040A87"
    copy(pix);
    setMsg("Copiado para a área de transferência!");
    setOpenMsg(true);
  };

  return (
    <div className={classes.todo}>
      {/* <img src={LogoFasoft} alt="logo Fasoft" width={"70%"} /> */}
      {/* <br /> */}
      <Card className={classes.card} elevation={3}>

        <img src={img1} alt="logo Fasoft" width={"100%"} />

      </Card>
      <Button
        className={classes.btInsc}
        size="large"
        variant="contained"
        fullWidth
        color="primary"
        component={Link}
        to="cadastro/"
      >
        INSCRIÇÃO INDIVIDUAL AQUI!
      </Button>
      <br />
      <div className={classes.container}>
        <Typography gutterBottom variant="h6" component="h3">
          ARRECADAÇÃO EVENTO
        </Typography>
        <Typography gutterBottom variant="h3" component="h3">
          R$ 5,00
        </Typography>
        {/* <TextField
        className={classes.quebraLinha}
        id="outlined-multiline-static"
        label="ChavePix"
        multiline
        variant="outlined"
        value="00020101021126680014br.gov.bcb.pix0129vanessamarquesalves@gmail.com0213Evento fasoft520400005303986540515.005802BR5920VANESSA M A DA SILVA6009RIO VERDE62160512EventoFasoft6304B661"
        fullWidth
      />
      <br /> */}
        <div className={classes.btsQR}>
          <Button
            size="large"
            variant="contained"
            className={classes.btCopy}
            fullWidth
            color="primary"
            onClick={() => copyPix()}
          >
            COPIAR CHAVE PIX
          </Button>
          <Button
            size="large"
            variant="contained"
            className={classes.icoQR}
            fullWidth
            color="primary"
            onClick={() => handleClickOpen()}
          >
            <img src={icoQR} alt="icoQrCode" width="30px" height="30px" />
          </Button>
        </div>
      </div>
      <div className={classes.container}>
        <Typography gutterBottom variant="h6" component="h6">
          ARRECADAÇÃO EVENTO + R$ 1,00 de DOAÇÃO
        </Typography>
        <Typography gutterBottom variant="h3" component="h3">
          R$ 6,00
        </Typography>
        <Button onClick={() => handleClickOpenDoacao()} color="secondary">
          Saiba mais
        </Button>
        <div className={classes.btsQR}>
          <Button
            size="large"
            variant="contained"
            className={classes.btCopy}
            fullWidth
            color="secondary"
            onClick={() => copyDoacao()}
          >
            COPIAR CHAVE PIX
          </Button>
          <Button
            size="large"
            variant="contained"
            className={classes.icoQR}
            fullWidth
            color="secondary"
            onClick={() => handleClickOpenQrDoacao()}
          >
            <img src={icoQR} alt="icoQrCode" width="30px" height="30px" />
          </Button>
        </div>
      </div>
      <br />
      <Button
        size="large"
        variant="contained"
        fullWidth
        color="primary"
        component={Link}
        to="comprovante/"
      >
        Enviar Comprovante
      </Button>
      <br />
      <br />
      <Button
        className={classes.btInsc}
        size="large"
        variant="contained"
        fullWidth
        color="primary"
        onClick={grupo}
      >
        INSCRIÇÃO DO GRUPO AQUI!
      </Button>
      <br />
      <br />
      {/* <Button
        size="large"
        fullWidth
        color="primary"
        onClick={() => edital()}
      >
        Edital
      </Button> */}
      <Button
        size="large"
        fullWidth
        color="primary"
        onClick={() => regulamento()}
      >
        EDITAL REGULAMENTAR DA XII GINCANA DA FASOFT
      </Button>
      <Button
        size="large"
        fullWidth
        color="primary"
        onClick={() => regulamentoJogos()}
      >
        Regulamento Jogos Soft`Sports
      </Button>
      <br />
      {/* <br /> */}
      {/* <img src={LogoUniRV} alt="logo Fasoft" width={"30%"} /> */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        open={openMsg}
        onClose={handleClose}
        message={Msg}
      ></Snackbar>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <img src={QrCode} alt="QrCode" width="100%" height="100%" />
      </Dialog>

      <Dialog
        open={openQrDoacao}
        onClose={handleClose}
      >
        <img src={QrCodeDoacao} alt="QrCode" width="100%" height="100%" />
      </Dialog>

      <Dialog
        open={openDoacao}
        onClose={handleClose}

      >
        <img src={Doacao} alt="QrCode" width="100%" />
      </Dialog>

    </div>
  );
});

export default Home;
