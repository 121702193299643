import { memo, useCallback, useRef, useState, forwardRef } from "react";
import Webcam from "react-webcam";
import Axios from "axios";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Send from "@material-ui/icons/Send";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import LogoFasoft from "../../assets/images/fasoft.svg";
import LogoUniRV from "../../assets/images/unirv.svg";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles } from "./style";
import img2 from "../../assets/images/img1.jpeg";
import Card from "@material-ui/core/Card";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Cadastro = memo(() => {
  const classes = useStyles();

  const webcamRef = useRef(null);
  const [open, setOpen] = useState(false);

  const [nome, setNome] = useState(null);
  const [email, setEmail] = useState(null);
  const [periodo, setPeriodo] = useState(1);
  const [turma, setTurma] = useState("A");
  const [imgSrc, setImgSrc] = useState('false');
  const [senha, setSenha] = useState(null);
  const [confSenha, setConfSenha] = useState(null);
  // const [validaSenha, setValidaSenha] = useState(false);

  // const validarSenha = (tipo, value) => {
  //   if (tipo === "senha") {
  //     setSenha(value);
  //   } else {
  //     setConfSenha(value);
  //   }

  //   if (value === '' || value === null || value === undefined) {
  //     setValidaSenha(false);
  //   } else {
  //     if (senha === value) {
  //       setValidaSenha(true);
  //     } else {
  //       setValidaSenha(false);
  //     }
  //   }
  // }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpenMsg(false);

    if (sus) {
      window.location.href = "/#/arrecadacao";
    }
  };

  const [openStatus, setOpenStatus] = useState(false);

  const [Msg, setMsg] = useState("false");

  const [openMsg, setOpenMsg] = useState(false);

  const [sus, setSus] = useState(false);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot({});
    setImgSrc(imageSrc);
    setOpen(false);
  }, []);

  const salvar = () => {
    setOpenStatus(true);
    Axios.post("https://calouros-2022-1.herokuapp.com/calouros", {
      name: nome,
      email: email,
      periodo: periodo,
      password: 'senha',
      confirmPassword: 'senha',
      turma: turma,
      imgUrl: "false", // confirmação de pagamento
      imgBi: imgSrc,
    })
      .then((response) => {
        console.log("aqui: ", response);
        setMsg("Cadastrado com Sucesso");
        setOpenMsg(true);
        setSus(true);
      })
      .catch(function (error) {
        setMsg(error.response.data);
        setOpenMsg(true);
      })
      .finally(() => {
        setOpenStatus(false);
      });
  };

  return (
    <div className={classes.todo}>
      {/* <img src={LogoFasoft} alt="logo Fasoft" width={"70%"} /> */}

      <Card className={classes.card} elevation={3}>

        <img src={img2} alt="logo Fasoft" width={"100%"} />

      </Card>

      <br />
      <form className={classes.form} onSubmit={(e) => console.log(e)}>
        <TextField
          label="Nome Completo"
          variant="outlined"
          name="NomeCompleto"
          required
          onChange={(e) => setNome(e.target.value)}
        />
        <TextField
          label="E-mail Academico"
          variant="outlined"
          name="email"
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <Select
          labelId="periodo"
          variant="outlined"
          value={periodo}
          required
          onChange={(e) => setPeriodo(e.target.value)}
        >
          <MenuItem value={1}>1° Periodo</MenuItem>
          <MenuItem value={2}>2° Periodo</MenuItem>
          <MenuItem value={3}>3° Periodo</MenuItem>
          <MenuItem value={4}>4° Periodo</MenuItem>
          <MenuItem value={5}>5° Periodo</MenuItem>
          <MenuItem value={6}>6° Periodo</MenuItem>
          <MenuItem value={7}>7° Periodo</MenuItem>
          <MenuItem value={8}>8° Periodo</MenuItem>
          <MenuItem value={9}>Professores</MenuItem>
        </Select>
        <Select
          labelId="Turma"
          variant="outlined"
          defaultValue={"A"}
          // value={periodo}
          onChange={(e) => setTurma(e.target.value)}
        >
          <MenuItem value={"U"}>Unica Turma</MenuItem>
          <MenuItem value={"A"}>Turma A</MenuItem>
          <MenuItem value={"B"}>Turma B</MenuItem>
          <MenuItem value={"C"}>Turma C</MenuItem>
          <MenuItem value={"P"}>Professor</MenuItem>
        </Select>

        {/* <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          endIcon={<PhotoCamera />}
          onClick={handleClickOpen}
          size="large"
          fullWidth
        >
          selfie
        </Button> */}
        {/* {imgSrc && <img src={imgSrc} alt="logo Fasoft" width={"70%"} />} */}

        {/* <TextField
          label="Senha"
          variant="outlined"
          name="senha"
          required
          type="password"
          onChange={(e) => validarSenha('senha',e.target.value)}
        />
        <TextField
          label="Confirmar Senha"
          variant="outlined"
          name="confSenha"
          required
          type="password"
          onChange={(e) => validarSenha('confSenha', e.target.value)}
        /> */}

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<Send />}
          size="large"
          fullWidth
          disabled={nome !== null && email !== null ? false : true}
          onClick={() => salvar()}
        >
          Enviar Inscrição
        </Button>
      </form>
      <br />
      <img src={LogoUniRV} alt="logo Fasoft" width={"30%"} />
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={classes.dialog}
      >
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          className={classes.webcam}
        />

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<PhotoCamera />}
          onClick={capture}
          size="large"
          fullWidth
        >
          Tirar Foto
        </Button>
      </Dialog>

      <Backdrop
        className={classes.backdrop}
        open={openStatus}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        autoHideDuration={1000}
        open={openMsg}
        onClose={handleClose}
        message={Msg}
      ></Snackbar>
    </div>
  );
});

export default Cadastro;
